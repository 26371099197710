import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataService from '../DataService';
import PostForm from '../components/PostForm';
import { CircularProgress } from '@mui/material';

const EditPost = () => {
  const { postId } = useParams();
  const [initialData, setInitialData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const post = await DataService.getPostById(postId);
        setInitialData({
          videoUrl: post.videoUrl,
          title: post.title,
          description: post.description,
          tags: post.tags
        });
      } catch (error) {
        console.error("Error fetching post details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [postId]);

  if (loading) return <CircularProgress />;
  if (!initialData) return <p>Error loading post data.</p>;

  return <PostForm postId={postId} initialData={initialData} />;
};

export default EditPost;
