import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Autocomplete
} from '@mui/material';
import DataService from '../DataService';

const SendPostDialog = ({ open, onClose, postId }) => {
  const [notificationType, setNotificationType] = useState('user');
  const [targetId, setTargetId] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (notificationType === 'user') {
          const users = await DataService.getAllUsers();
          setSearchOptions(users.map(user => ({ label: user.name || user.email, id: user.userId })));
        } else {
          const teams = await DataService.getTeams();
          setSearchOptions(teams.map(team => ({ label: team.name, id: team.teamId })));
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [notificationType]);

  const handleSendNotification = async () => {
    try {
      if (notificationType === 'user') {
        await DataService.sendPostNotificationToUser(targetId, postId);
      } else {
        await DataService.sendPostNotificationToTeam(targetId, postId);
      }
      onClose();
    } catch (error) {
      alert('Error sending notification: ' + JSON.stringify(error));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Send Post Notification</DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Notification Type"
          value={notificationType}
          onChange={(e) => setNotificationType(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="team">Team</MenuItem>
        </TextField>
        <Autocomplete
          options={searchOptions}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => setTargetId(newValue ? newValue.id : '')}
          renderInput={(params) => <TextField {...params} label={`Search for ${notificationType === 'user' ? 'User' : 'Team'}`} fullWidth margin="normal" />}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSendNotification} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendPostDialog;