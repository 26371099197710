// src/components/TagFilter.js
import React from 'react';
import { Box, Chip, Typography } from '@mui/material';

const TagFilter = ({ title, tags, selectedTags, onTagToggle }) => {
  return (
    <div>
      {tags.length > 0 ? 
      <Box textAlign="center" mt={2}>
        <Box display="inline-flex" flexWrap="wrap" justifyContent="center" gap={1} mb={2}>
          <Typography variant="body1" sx={{lineHeight: 2, color: '#0009', fontStyle: 'italic', marginRight: 1}}>{title}</Typography>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant={selectedTags.includes(tag) ? 'filled' : 'outlined'}
              color={selectedTags.includes(tag) ? 'primary' : 'default'}
              onClick={() => onTagToggle(tag)}
            />
          ))}
        </Box>
      </Box> : <div style={{marginTop: '16px'}}></div>}
    </div>
  );
};

export default TagFilter;