import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { Paper, Typography, Box, Avatar, List, ListItem, ListItemText, Divider, Chip, LinearProgress, TextField, Button, IconButton } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { PhotoCamera } from '@mui/icons-material';
import DataService from '../DataService';

const supabase = createClient('https://eitmkdmwsrwkxwleftxj.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVpdG1rZG13c3J3a3h3bGVmdHhqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg5MjM2MzEsImV4cCI6MjAzNDQ5OTYzMX0.IYhxI4Tg_ETrfXEcaJHJlExl0DfWqt_H4a_ZRoRcEAs');

const UserProfile = () => {
    const { id } = useParams();
    const [currentUser, setCurrentUser] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [enneagramNumber, setEnneagramNumber] = useState('');
    const [cliftonStrengths, setCliftonStrengths] = useState([]);
    const [newStrength, setNewStrength] = useState('');
    const [profilePictureUrl, setProfilePictureUrl] = useState('/default-profile.png');

    const avatarStyle = { bgcolor: deepPurple[500], width: 100, height: 100 };

    useEffect(() => {
        const fetchCurrentUser = async () => {
            const { data: { user } } = await supabase.auth.getUser();
            setCurrentUser(user);
        };

        const fetchUser = async () => {
            setLoading(true);
            try {
                const userData = await DataService.getUserById(id);
                setUser(userData);
                setName(userData.name || '');
                setEmail(userData.email || '');
                setDescription(userData.description || '');
                setEnneagramNumber(userData.enneagramNumber || '');
                setCliftonStrengths(userData.cliftonStrengths || []);
                setProfilePictureUrl(userData.profilePicture || '/default-profile.png');
            } catch (error) {
                setError('Failed to load user details.');
            }
            setLoading(false);
        };

        fetchCurrentUser();
        fetchUser();
    }, [id]);

    const fetchUser = async () => {
        setLoading(true);
        try {
            const userData = await DataService.getUserById(id);
            setUser(userData);
            setName(userData.name || '');
            setEmail(userData.email || '');
            setDescription(userData.description || '');
            setEnneagramNumber(userData.enneagramNumber || '');
            setCliftonStrengths(userData.cliftonStrengths || []);
            setProfilePictureUrl(userData.profilePicture || '/default-profile.png');
        } catch (error) {
            setError('Failed to load user details.');
        }
        setLoading(false);
    };

    const handleAddStrength = () => {
        if (newStrength) {
            setCliftonStrengths([...cliftonStrengths, newStrength]);
            setNewStrength('');
        }
    };

    const handleDeleteStrength = (index) => {
        setCliftonStrengths(cliftonStrengths.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        try {
            await DataService.updateUserProfile(id, name, email, description, cliftonStrengths, enneagramNumber, profilePictureUrl);
            setIsEditing(false);
            // Refresh user data after saving
            fetchUser();
        } catch (error) {
            setError('Failed to save user details.');
        }
    };

    const handleProfilePictureUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        try {
            const path = await DataService.uploadPublicBlob(file);
            const publicUrl = (await DataService.getPublicBlobUrl(path)).publicUrl;
            setProfilePictureUrl(publicUrl || '/default-profile.png');
            await DataService.updateUserProfile(id, name, email, description, cliftonStrengths, enneagramNumber, publicUrl);
            fetchUser();
        } catch (error) {
            setError('Failed to upload profile picture.');
        }
    };

    if (loading) return <LinearProgress color="primary" />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" p={2}>
            {user && (
                <Paper elevation={3} sx={{ maxWidth: 600, p: 4, width: '100%' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                        <Box position="relative">
                            <Avatar src={profilePictureUrl} sx={avatarStyle} />
                            {currentUser && currentUser.id === id && (
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                    sx={{ position: 'absolute', bottom: 0, right: 0 }}
                                >
                                    <input hidden accept="image/*" type="file" onChange={handleProfilePictureUpload} />
                                    <PhotoCamera />
                                </IconButton>
                            )}
                        </Box>
                        <Typography variant="h4">{user.name}</Typography>
                        <Typography variant="subtitle1" color="text.secondary">{user.email}</Typography>
                        {isEditing ? (
                            <TextField
                                label="Description"
                                fullWidth
                                multiline
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                margin="normal"
                            />
                        ) : (
                            <Typography p={2} textAlign="center">{user.description}</Typography>
                        )}
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6">Clifton Strengths</Typography>
                    {isEditing ? (
                        <>
                            <TextField
                                label="New Clifton Strength"
                                fullWidth
                                value={newStrength}
                                onChange={(e) => setNewStrength(e.target.value)}
                                margin="normal"
                            />
                            <Button onClick={handleAddStrength} color="primary" variant="contained">Add Strength</Button>
                            <List>
                                {cliftonStrengths.map((strength, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={strength} />
                                        <Button onClick={() => handleDeleteStrength(index)} color="secondary">Delete</Button>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    ) : (
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            {user.cliftonStrengths?.map((strength, index) => (
                                <Chip key={index} label={strength} variant="outlined" />
                            ))}
                        </Box>
                    )}

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6">Enneagram Number</Typography>
                    {isEditing ? (
                        <TextField
                            label="Enneagram Number"
                            fullWidth
                            value={enneagramNumber}
                            onChange={(e) => setEnneagramNumber(e.target.value)}
                            margin="normal"
                        />
                    ) : (
                        <Typography>{user.enneagramNumber || 'Not specified'}</Typography>
                    )}

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h6">Teams</Typography>
                    <List>
                        {user.teamAssignments.map((team) => (
                            <ListItem key={team.teamId}>
                                <ListItemText primary={`${team.teamName}`} secondary={team.isManager ? 'Manager' : 'Member'} />
                            </ListItem>
                        ))}
                    </List>

                    {currentUser && currentUser.id === id && (
                        <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
                            {isEditing ? (
                                <>
                                    <Button onClick={() => setIsEditing(false)} color="secondary">Cancel</Button>
                                    <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
                                </>
                            ) : (
                                <Button onClick={() => setIsEditing(true)} color="primary" variant="contained">Edit</Button>
                            )}
                        </Box>
                    )}
                </Paper>
            )}
        </Box>
    );
};

export default UserProfile;
