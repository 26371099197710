import React, { useState, useEffect } from 'react';
import DataService from '../DataService';
import {
  Container,
  Paper,
  Grid,
  TextField,
  Button,
  Chip,
  Box,
  CircularProgress,
  Backdrop,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PostForm = ({ postId, initialData }) => {
  const [videoBlobPath, setVideoBlobPath] = useState(initialData?.videoBlobPath || '');
  const [videoUrl, setVideoUrl] = useState(initialData?.videoUrl || '');
  const [title, setTitle] = useState(initialData?.title || '');
  const [description, setDescription] = useState(initialData?.description || '');
  const [tags, setTags] = useState(initialData?.tags || []);
  const [tagInput, setTagInput] = useState('');
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoKey, setVideoKey] = useState(0); // New state for video key
  const [errors, setErrors] = useState({}); // New state for error messages
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTags = async () => {
      const data = await DataService.getAllTags();
      setAllTags(data);
    };
    fetchTags();
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ['video/mp4', 'video/quicktime'];
      if (!validFileTypes.includes(file.type)) {
        setErrors(prevErrors => ({ ...prevErrors, videoBlobPath: 'Invalid file type. Only MP4 and MOV files are allowed.' }));
        return;
      }

      setLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        setVideoUrl(e.target.result);
        setVideoKey(prevKey => prevKey + 1); // Update video key to force re-render
      };
      reader.readAsDataURL(file);

      try {
        let path = await DataService.uploadBlob(file);
        setVideoBlobPath(path);

        // Update the video URL to the new uploaded video path
        setVideoUrl(URL.createObjectURL(file));
        setVideoKey(prevKey => prevKey + 1); // Update video key to force re-render
        setErrors(prevErrors => ({ ...prevErrors, videoBlobPath: '' }));
      } catch (err) {
        setErrors(prevErrors => ({ ...prevErrors, videoBlobPath: 'Error uploading video.' }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTagKeyDown = async (event) => {
    if (event.key === 'Enter' && tagInput.trim()) {
      const newTag = tagInput.trim();
      try {
        setLoading(true);
        await DataService.createTag(newTag);
        setTags([...tags, newTag]);
        setAllTags([...allTags, { name: newTag }]);
        setTagInput('');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTagAddButton = async (event) => {
    const newTag = tagInput.trim();
    try {
      setLoading(true);
      await DataService.createTag(newTag);
      setTags([...tags, newTag]);
      setAllTags([...allTags, { name: newTag }]);
      setTagInput('');
    } finally {
      setLoading(false);
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags(tags.filter(tag => tag !== tagToDelete));
  };

  const handleTagSelect = (event) => {
    const selectedTag = event.target.value;
    if (!tags.includes(selectedTag)) {
      setTags([...tags, selectedTag]);
    }
  };

  const handleSubmit = async () => {
    if (loading) return;

    // Validation check
    const newErrors = {};
    if (!videoBlobPath && !videoUrl) newErrors.videoBlobPath = 'Please upload a video.';
    if (!title.trim()) newErrors.title = 'Please enter a title.';
    if (!description.trim()) newErrors.description = 'Please enter a description.';
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      if (postId) {
        await DataService.updatePost(postId, videoBlobPath, title, description, tags);
      } else {
        await DataService.createPost(title, description, videoBlobPath, tags);
      }
      handleCancel(); // Reset form upon successful submission
    } catch (err) {
      setErrors(prevErrors => ({ ...prevErrors, submit: 'Error creating post.' }));
    } finally {
      setLoading(false);
    }

    // Navigate to /index to see the new post
    navigate('/');
  };

  const handleCancel = () => {
    setTitle('');
    setDescription('');
    setTags([]);
    setVideoUrl('');
    navigate('/');
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} style={{ padding: 16 }}>
        <h2>{postId ? 'Edit Post' : 'Create Post'}</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <input
              accept="video/mp4,video/quicktime"
              style={{ display: 'none' }}
              id="video-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="video-upload">
              <Button variant="contained" color="primary" component="span">
                Upload Video
              </Button>
            </label>
            {errors.videoBlobPath && (
              <Typography color="error" variant="body2">{errors.videoBlobPath}</Typography>
            )}
          </Grid>

          {videoUrl && (
            <Grid item xs={12}>
              <video key={videoKey} width="100%" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>

          <Grid item xs={12}>
            {allTags.length > 0 ?             
              <FormControl fullWidth>
                <InputLabel>Select Tag</InputLabel>
                <Select
                  value=""
                  onChange={handleTagSelect}
                  displayEmpty
                >
                  {allTags.map((tag, index) => (
                    <MenuItem key={tag.tagId || index} value={tag.name}>
                      {tag.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            : <div />}
            <Stack direction="row">
              <TextField
                label="Add New Tag"
                fullWidth
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onKeyDown={handleTagKeyDown}
                helperText="Press Enter to add a new tag"
                style={{ marginTop: 8 }}
              />
              <Button variant="contained" color="primary" onClick={handleTagAddButton} sx={{ margin: '8px 0px 23px 4px' }} disabled={loading}>
                Add
              </Button>
            </Stack>
            <Box mt={1}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleTagDelete(tag)}
                  style={{ marginRight: 8, marginBottom: 8 }}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginRight: 8 }} disabled={loading}>
              {postId ? 'Update' : 'Submit'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
          </Grid>
        </Grid>
        {errors.submit && (
          <Typography color="error" variant="body2">{errors.submit}</Typography>
        )}
      </Paper>
      <Backdrop open={loading} style={{ zIndex: 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default PostForm;
