import React, { useState, useEffect, useCallback } from 'react';
import DataService from '../DataService';
import { Container, LinearProgress, Stack, List } from '@mui/material';
import StreakDisplay from '../components/Streak';
import TagFilter from '../components/TagFilter';
import SendPostDialog from '../components/SendPostDialog';
import UserPost from '../components/UserPost';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [streak, setStreak] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isManager, setIsManager] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogPostId, setDialogPostId] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const currentAuthUser = await DataService.getCurrentUser();
        const user = await DataService.getUserById(currentAuthUser.id);
        setIsManager(user.isManager);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleAddComment = useCallback(async (postId, comment) => {
    const commentData = await DataService.addComment(postId, comment);
    setPosts((prevPosts) =>
      prevPosts.map((post) => {
        if (post.postId === postId) {
          return { ...post, comments: [...post.comments, commentData] };
        }
        return post;
      })
    );
  }, []);

  const handlePostLiked = useCallback(async (postId) => {
    try {
      await DataService.likePost(postId);
      setPosts((prevPosts) =>
        prevPosts.map((post) => {
          if (post.postId === postId) {
            return { ...post, likesCount: post.likesCount + 1 };
          }
          return post;
        })
      );
    } catch (error) {
      console.error('Error liking post:', error);
    }
  }, []);

  const handlePinPost = useCallback(async (postId) => {
    try {
      await DataService.pinPost(postId);
      alert('Post pinned successfully');
    } catch (error) {
      alert('Error pinning post: ' + JSON.stringify(error));
    }
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        const [fetchedPosts, fetchedStreak, fetchedTags] = await Promise.all([
          DataService.getUserFeed(),
          DataService.getUserStreak(),
          DataService.getAllTags()
        ]);

        setPosts(fetchedPosts);
        setStreak(fetchedStreak);
        setAllTags(fetchedTags.map(tag => tag.name));
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchFilteredPosts = async () => {
      try {
        setLoading(true);
        const fetchedPosts = await DataService.getUserFeed(selectedTags);
        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Error fetching filtered posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredPosts();
  }, [selectedTags]);

  const handleTagToggle = (tag) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter(t => t !== tag)
        : [...prevSelectedTags, tag]
    );
  };

  const openSendDialog = (postId) => {
    setDialogPostId(postId);
    setOpenDialog(true);
  };

  const closeSendDialog = () => {
    setOpenDialog(false);
  };

  if (loading) return <LinearProgress color="primary" />;

  return (
    <Container maxWidth="md">
      <Stack alignItems="center">
        {streak >= 1 ? (<StreakDisplay streak={streak} />) : (<div />)}
      </Stack>
      <TagFilter
        title="Filter by"
        tags={allTags}
        selectedTags={selectedTags}
        onTagToggle={handleTagToggle}
      />
      <List sx={{paddingTop: 0}}>
        {posts.map((post) => (
          <UserPost
            key={post.postId}
            post={post}
            isManager={isManager}
            handlePostLiked={handlePostLiked}
            handlePinPost={handlePinPost}
            handleAddComment={handleAddComment}
            openSendDialog={openSendDialog}
            detailed='true'
          />
        ))}
      </List>
      <SendPostDialog open={openDialog} onClose={closeSendDialog} postId={dialogPostId} />
    </Container>
  );
};

export default Feed;