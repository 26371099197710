import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, ListItemText, Typography, Badge, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import DataService from '../DataService';

const UserNotificationsDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const currentUser = await DataService.getCurrentUser();
        const fetchedNotifications = await DataService.getUserNotifications(currentUser.id);
        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    handleClose();
    if (notification.postId) {
      navigate(`/post/${notification.postId}`);
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleOpen}>
        <Badge badgeContent={notifications.length} color="warning">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading ? (
          <MenuItem>
            <Typography>Loading...</Typography>
          </MenuItem>
        ) : notifications.length === 0 ? (
          <MenuItem>
            <Typography>No Notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem key={notification.notificationId} onClick={() => handleNotificationClick(notification)}>
              <ListItemText
                sx={{wordBreak: 'break-word', whiteSpace: 'normal', display: 'block'}}
                primary={notification.message}
                secondary={new Date(notification.createdAt).toLocaleString()}
              />
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default UserNotificationsDropdown;