import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, Container } from '@mui/material';
import Post from './pages/Post';
import Feed from './pages/Feed';
import TeamManagement from './pages/TeamManagement';
import UserProfile from './pages/UserProfile';
import SetPassword from './pages/SetPassword';
import LoginPage from './pages/Login';
import SinglePost from './pages/SinglePost';
import EditPost from './pages/EditPost'; // Add this import
import UserNotificationsDropdown from './components/UserNotificationsDropdown';
import { supabase } from './supabaseClient';
import DataService from './DataService';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function AppContent({ session, handleLogout, isAdmin }) {
  const location = useLocation();
  const isSetPasswordRoute = location.pathname === '/set-password';

  const isAuthenticated = useMemo(() => !!session, [session]);
  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#007cb3',
      },
      secondary: {
        main: 'rgba(34, 46, 55, 1)',
      },
      warning: {
        main: '#ebbf05'
      },
      info: {
        main: '#007cb3'
      },
      success: {
        main: '#6bbf63',
      },
    },
  });

  if (!isAuthenticated && !isSetPasswordRoute) {
    return <LoginPage />;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{background: 'linear-gradient(to right,rgba(34, 46, 55, 1),rgba(100, 121, 136, 1),rgba(34, 46, 55, 1))'}}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }} to="/">BODEE</Typography>
          <Button color="inherit" component={Link} to="/">Feed</Button>
          <Button color="inherit" component={Link} to="/post">Post</Button>
          {isAdmin && <Button color="inherit" component={Link} to="/team-management">Settings</Button> }
          
          <UserNotificationsDropdown />
          <Button color="inherit" onClick={handleLogout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Container>
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/post" element={<Post />} />
          {isAdmin && <Route path="/team-management" element={<TeamManagement />} />}
          <Route path="/user/:id" element={<UserProfile />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/post/:postId" element={<SinglePost />} />
          <Route path="/edit-post/:postId" element={<EditPost />} /> {/* Add this line */}
        </Routes>
      </Container>
    </ThemeProvider>
  );
}

function App() {
  const [session, setSession] = useState(null);  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async (session) => {
      try {
        const userData = await DataService.getUserById(session.user.id);
        setUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      if (session) {
        fetchCurrentUser(session);
      }
    });

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setSession(null);
  };

  return (
    <Router>
      <Routes>
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="*" element={<AppContent session={session} handleLogout={handleLogout} isAdmin={user?.isSystemAdmin} />} />
      </Routes>
    </Router>
  );
}

export default App;
