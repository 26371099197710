import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import DataService from '../DataService';
import { Container, LinearProgress, Typography } from '@mui/material';
import UserPost from '../components/UserPost';

const SinglePost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const currentAuthUser = await DataService.getCurrentUser();
        const user = await DataService.getUserById(currentAuthUser.id);
        setIsManager(user.isManager);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();

    const fetchPost = async () => {
      try {
        setLoading(true);
        const fetchedPost = await DataService.getPostById(postId);
        setPost(fetchedPost);
      } catch (error) {
        console.error("Error fetching post details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPost();
  }, [postId]);

  const handlePostLiked = useCallback(async (postId) => {
    try {
      await DataService.likePost(postId);
      setPost((post) => {
        return {...post, likesCount: post.likesCount + 1 };
      })
    } catch (error) {
      console.error('Error liking post:', error);
    }
  }, []);

  const handlePinPost = useCallback(async (postId) => {
    try {
      await DataService.pinPost(postId);
      alert('Post pinned successfully');
    } catch (error) {
      alert('Error pinning post: ' + JSON.stringify(error));
    }
  }, []);

  if (loading) return <LinearProgress color="primary" />;
  if (!post) return <Typography variant="h6" align="center">Post not found</Typography>;
  
  return (
    <Container maxWidth="md" sx={{mt: 4}}>
      <UserPost
        post={post}
        isManager={isManager}
        handlePostLiked={handlePostLiked}
        handlePinPost={handlePinPost}
        handleAddComment={() => {}}
        openSendDialog={() => {}}
        detailed
      />
    </Container>
  );
};

export default SinglePost;