import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Avatar, IconButton, Chip, CircularProgress } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { supabase } from '../supabaseClient';
import DataService from '../DataService';

const SetPassword = ({ setSession }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [enneagramNumber, setEnneagramNumber] = useState('');
  const [cliftonStrengths, setCliftonStrengths] = useState([]);
  const [newStrength, setNewStrength] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('/default-profile.png');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const setSessionFromToken = async () => {
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const refreshToken = hashParams.get('refresh_token');

      if (accessToken && refreshToken) {
        await supabase.auth.setSession({ access_token: accessToken, refresh_token: refreshToken });
        const { data: { session } } = await supabase.auth.getSession();
        setSession(session);
        fetchUserProfile(session.user.id); // Fetch user profile after setting session
      } else {
        setLoading(false);
      }
    };

    setSessionFromToken();
  }, [location, setSession]);

  const fetchUserProfile = async (userId) => {
    try {
      const userProfile = await DataService.getUserById(userId);
      
      setName(userProfile.name);
      setProfilePictureUrl(userProfile.profilePictureUrl || '/default-profile.png');
      // Set other fields accordingly if needed
    } catch (fetchError) {
      setError('Failed to load user profile.');
    } finally {
      setLoading(false);
    }
  };

  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setLoading(true);
    try {
      const path = await DataService.uploadPublicBlob(file);
      const publicUrl = (await DataService.getPublicBlobUrl(path)).publicUrl;
      setProfilePictureUrl(publicUrl || '/default-profile.png');
    } catch (uploadError) {
      setError('Failed to upload profile picture.');
    }
    setLoading(false);
  };

  const handleAddStrength = () => {
    if (newStrength) {
      setCliftonStrengths([...cliftonStrengths, newStrength]);
      setNewStrength('');
    }
  };

  const handleDeleteStrength = (index) => {
    setCliftonStrengths(cliftonStrengths.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({ password });
      if (error) throw error;

      const { data: { user } } = await supabase.auth.getUser();
      await DataService.updateUser(user.id, name, user.email, description, cliftonStrengths, enneagramNumber,
                                   user.teamAssignments, profilePictureUrl);

      navigate('/');
    } catch (dataError) {
      setError('Failed to update user profile.');
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" align="center">Set Password & Complete Profile</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <Avatar src={profilePictureUrl} sx={{ width: 100, height: 100 }} />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{ ml: 2 }}
            >
              <input hidden accept="image/*" type="file" onChange={handleProfilePictureUpload} />
              <PhotoCamera />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Enneagram Number"
            value={enneagramNumber}
            onChange={(e) => setEnneagramNumber(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="New Clifton Strength"
            value={newStrength}
            onChange={(e) => setNewStrength(e.target.value)}
          />
          <Button onClick={handleAddStrength} color="primary" variant="contained" sx={{ mt: 1 }}>
            Add Strength
          </Button>
          <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
            {cliftonStrengths.map((strength, index) => (
              <Chip
                key={index}
                label={strength}
                onDelete={() => handleDeleteStrength(index)}
              />
            ))}
          </Box>
          {error && <Typography color="error" mt={2}>{error}</Typography>}
          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Save & Complete'}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default SetPassword;