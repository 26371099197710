import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  Divider,
  Menu,
  MenuItem
} from '@mui/material';
import { ThumbUp, PushPin as PinIcon, Send as SendIcon, MoreVert as MoreVertIcon} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';
import DataService from '../DataService';
import { useNavigate } from 'react-router-dom';

const UserPost = ({ post, isManager, handlePostLiked, handlePinPost, handleAddComment, openSendDialog, detailed }) => {
  const [comments, setComments] = useState(post.comments || []);
  const [newComment, setNewComment] = useState('');
  const [isAuthor, setIsAuthor] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditPost = () => {
    handleMenuClose();
    navigate(`/edit-post/${post.postId}`);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const currentAuthUser = await DataService.getCurrentUser();
        setIsAuthor(post.authorUserId === currentAuthUser.id);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserDetails();
  }, [post.authorUserId]);

  useEffect(() => {
    const fetchComments = async () => {
      if (detailed) {
        try {
          const fetchedPost = await DataService.getPostById(post.postId);
          setComments(fetchedPost.comments || []);
        } catch (error) {
          console.error("Error fetching post comments:", error);
        }
      }
    };
    fetchComments();
  }, [post.postId, detailed]);

  const addComment = useCallback(async () => {
    if (newComment.trim()) {
      const commentData = await DataService.addComment(post.postId, newComment);
      setComments([...comments, commentData]);
      setNewComment(''); // Clear input after sending
    }
  }, [newComment, post.postId, comments]);

  const handleDeletePost = useCallback(async () => {
    try {
      await DataService.deletePost(post.postId);
      setIsDeleted(true); // Hide the component
      handleMenuClose();
    } catch (error) {
      alert('Error deleting post: ' + JSON.stringify(error));
    }
  }, [post.postId]);

  if (isDeleted) {
    return null; // Return null to hide the component
  }

  return (
    <Card variant="outlined" sx={{ margin: 'auto', mb: 4, borderWidth: 2 }}>
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant={detailed ? "h5" : "h6"} component="div">
          {detailed ? post.title : <Link to={`/user/${post.authorUserId}`} style={{ textDecoration: 'none', color: 'inherit' }}>{post.userName}</Link>}
        </Typography>
        {isAuthor && (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleEditPost}>
                Edit
              </MenuItem>
              <MenuItem onClick={handleDeletePost}>
                Delete
              </MenuItem>
            </Menu>
          </Box>
        )}
      </CardContent>
      <CardContent>
        {detailed && (
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            <Link to={`/user/${post.authorUserId}`} style={{color: 'inherit' }}>
              {post.userName}
            </Link>
          </Typography>
        )}
        <Typography variant="body2" color="text.secondary" sx={{marginBottom: 2}}>
          {post.description}
        </Typography>
        <Box sx={{flexWrap: 'wrap', gap: 1}} display="flex" alignItems="center" mt={1}>
          {post.isPinned && (
            <Chip label="Pinned by your manager" color="primary" size="small" sx={{ mr: 1 }} />
          )}
          {post.isByTeammate && (
            <Chip label="Posted by your teammate" color="secondary" size="small" />
          )}
        </Box>
      </CardContent>
      <LazyLoadVideo url={post.videoUrl} />
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton aria-label="like" onClick={() => handlePostLiked(post.postId)}>
          <ThumbUp />
        </IconButton>
        <Typography variant="body2" color="text.secondary">
          {post.likesCount}
        </Typography>
        <Box sx={{ marginLeft: 'auto' }}>
          {isManager && (
            <IconButton color="primary" onClick={() => handlePinPost(post.postId)}>
              <PinIcon />
            </IconButton>
          )}
          <IconButton color="primary" onClick={() => openSendDialog(post.postId)}>
            <SendIcon />
          </IconButton>
        </Box>
      </CardContent>
      <CardContent>
        <Accordion elevation={0} style={{ border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '4px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Comments ({comments.length})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {comments.map((comment, index) => (
              <Box key={index} sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <Typography variant="subtitle2" color="text.primary" gutterBottom>
                  {comment.userName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {comment.content}
                </Typography>
                {index < comments.length - 1 && <Divider />}
              </Box>
            ))}
            <Box sx={{ display: 'flex', gap: 1, paddingTop: 2 }}>
              <TextField
                id={`comment-input-${post.postId}`}
                label="Add a comment"
                variant="outlined"
                size="small"
                fullWidth
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && newComment.trim()) {
                    addComment();
                  }
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={addComment}
              >
                Submit
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box mt={2}>
          {post.tags && post.tags.map((tag, index) => (
            <Chip key={index} label={tag} variant="outlined" sx={{ marginRight: 1, marginBottom: 1 }} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const LazyLoadVideo = ({ url }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });

  return (
    <div ref={ref} style={{ paddingTop: '56.25%', position: 'relative' }}>
      {inView && (
        <ReactPlayer
          url={url}
          controls
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
    </div>
  );
};

export default UserPost;
