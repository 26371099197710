import React from 'react';
import { Box, Typography, Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const StreakBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  width: '-webkit-fill-available',
  border: '2px solid #ebbf05',
  borderRadius: theme.shape.borderRadius,
}));

const StreakAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  background: 'linear-gradient(45deg,rgba(34, 46, 55, 1),rgba(100, 121, 136, 1), #ebbf05)',
  marginBottom: theme.spacing(1),
}));

const StreakDisplay = ({ streak }) => {
  return (
    <StreakBox>
      <Tooltip title="Current Streak" arrow>
        <StreakAvatar>
          <EmojiEventsIcon fontSize="large" color="#1976d2" />
        </StreakAvatar>
      </Tooltip>
      <Typography variant="h4" component="div"  color="primary">
        {streak} Day Streak
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Keep it up!
      </Typography>
    </StreakBox>
  );
};

export default StreakDisplay;