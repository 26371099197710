import React, { useState, useEffect } from 'react';
import DataService from '../DataService';
import {
  Container,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Chip,
  useMediaQuery,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

const TeamManagement = () => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openAddTeamDialog, setOpenAddTeamDialog] = useState(false);
  const [openEditTeamDialog, setOpenEditTeamDialog] = useState(false);
  const [openTagDialog, setOpenTagDialog] = useState(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [scheduleMessage, setScheduleMessage] = useState('');
  const [scheduledTime, setScheduledTime] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [feedback, setFeedback] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchUsers = async () => {
      const data = await DataService.getAllUsers();
      setUsers(data);
    };

    const fetchTeams = async () => {
      const data = await DataService.getTeams();
      setTeams(data);
    };

    const fetchTags = async () => {
      const data = await DataService.getAllTags();
      setTags(data);
    };

    fetchUsers();
    fetchTeams();
    fetchTags();
  }, []);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setOpenUserDialog(true);
  };

  const handleDeleteUser = (user) => {
    DataService.deleteUser(user.userId);
    setUsers(users.filter(u => u.userId !== user.userId));
  };

  const handleEditTeam = async (team) => {
    const teamDetails = await DataService.getTeamDetails(team.teamId);
    setSelectedTeam(teamDetails);
    setOpenEditTeamDialog(true);
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setOpenTagDialog(true);
  };

  const handleUserDialogClose = () => setOpenUserDialog(false);
  const handleAddTeamDialogClose = () => setOpenAddTeamDialog(false);
  const handleEditTeamDialogClose = () => setOpenEditTeamDialog(false);
  const handleTagDialogClose = () => setOpenTagDialog(false);
  const handleScheduleDialogClose = () => setOpenScheduleDialog(false);

  const handleAddUser = () => {
    setSelectedUser(null); // Ensure no user is selected
    setOpenUserDialog(true);
  };

  const handleAddTeam = () => {
    setSelectedTeam(null); // Ensure no team is selected
    setOpenAddTeamDialog(true);
  };

  const handleAddTag = () => {
    setSelectedTag(null); // Ensure no tag is selected
    setOpenTagDialog(true);
  };

  const handleScheduleNotification = async () => {
    try {
      await Promise.all(
        selectedUsers.map(async (userId) => {
          await DataService.scheduleNotification(scheduleMessage, scheduledTime, userId);
        })
      );
      setFeedback('Notification scheduled successfully!');
    } catch (error) {
      setFeedback('Error scheduling notification.');
    }
    setOpenScheduleDialog(false);
  };

  const handleSelectChange = (event) => {
    setSelectedUsers(event.target.value);
  };

  return (
    <Container maxWidth="lg" sx={{mt: 4}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6">Users</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  {!isMobile && <TableCell>Email</TableCell>}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.userId}>
                    <TableCell>{user.name}</TableCell>
                    {!isMobile && <TableCell>{user.email}</TableCell>}
                    <TableCell align='right'>
                      <Button variant="contained" color="primary" onClick={() => handleEditUser(user)} size={isMobile ? 'small' : 'medium'}>
                        Edit User
                      </Button>
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user)}
                      size={isMobile ? 'small' : 'medium'}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button sx={{mt: 2}} variant="contained" color="primary" onClick={handleAddUser} fullWidth={isMobile}>
              Add User
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6">Teams</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((team) => (
                  <TableRow key={team.teamId}>
                    <TableCell>{team.name}</TableCell>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" onClick={() => handleEditTeam(team)} size={isMobile ? 'small' : 'medium'}>
                        Edit Team
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button sx={{mt: 2}} variant="contained" color="primary" onClick={handleAddTeam} fullWidth={isMobile}>
              Add Team
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6">Tags</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((tag) => (
                  <TableRow key={tag.tagId}>
                    <TableCell>{tag.name}</TableCell>
                    <TableCell align="right">
                      <Button variant="contained" color="primary" onClick={() => handleEditTag(tag)} size={isMobile ? 'small' : 'medium'}>
                        Edit Tag
                      </Button>
                      <IconButton edge="end" aria-label="delete" onClick={async () => {
                        await DataService.deleteTag(tag.tagId);
                        setTags(tags.filter(t => t.tagId !== tag.tagId));
                      }} size={isMobile ? 'small' : 'medium'}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button sx={{mt: 2}} variant="contained" color="primary" onClick={handleAddTag} fullWidth={isMobile}>
              Add Tag
            </Button>
          </Paper>
        </Grid>

        {/* Notification Management Section */}
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Typography variant="h6">Notification Management</Typography>
            <Button sx={{mt: 2}} variant="contained" color="secondary" onClick={() => setOpenScheduleDialog(true)}>
              Schedule Notification
            </Button>
          </Paper>
        </Grid>
      </Grid>
      
      {/* User Dialog */}
      <UserDialog open={openUserDialog} onClose={handleUserDialogClose} user={selectedUser} fetchUsers={() => DataService.getAllUsers().then(setUsers)} />
      <AddTeamDialog open={openAddTeamDialog} onClose={handleAddTeamDialogClose} fetchTeams={() => DataService.getTeams().then(setTeams)} />
      <EditTeamDialog open={openEditTeamDialog} onClose={handleEditTeamDialogClose} team={selectedTeam} fetchTeams={() => DataService.getTeams().then(setTeams)} />
      <TagDialog open={openTagDialog} onClose={handleTagDialogClose} tag={selectedTag} fetchTags={() => DataService.getAllTags().then(setTags)} />

      {/* Schedule Notification Dialog */}
      <Dialog open={openScheduleDialog} onClose={handleScheduleDialogClose}>
        <DialogTitle>Schedule Notification</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="multi-select-label">Select Users</InputLabel>
            <Select
              labelId="multi-select-label"
              multiple
              value={selectedUsers}
              onChange={handleSelectChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  <Checkbox checked={selectedUsers.indexOf(user.userId) > -1} />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField label="Message" fullWidth value={scheduleMessage} onChange={(e) => setScheduleMessage(e.target.value)} margin="normal" />
          <TextField label="Schedule Time" type="datetime-local" fullWidth InputLabelProps={{ shrink: true }} value={scheduledTime} onChange={(e) => setScheduledTime(e.target.value)} margin="normal" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScheduleDialogClose} color="error">Cancel</Button>
          <Button onClick={handleScheduleNotification} color="primary">Schedule</Button>
        </DialogActions>
      </Dialog>
      
      {feedback && <Typography color="textSecondary">{feedback}</Typography>}
    </Container>
  );
};

// UserDialog, EditTeamDialog, TagDialog components remain the same.
const UserDialog = ({ open, onClose, user, fetchUsers }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setName(user?.name || '');
    setEmail(user?.email || '');
  }, [user]);

  const handleSave = async () => {
    if (user) {
      await DataService.updateUser(user.userId, name, email);
    } else {
      await DataService.addNewUser(name, email);
    }

    fetchUsers();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{user ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">Cancel</Button>
        <Button onClick={handleSave} color="success">{user ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

const AddTeamDialog = ({ open, onClose, fetchTeams }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName('');
  }, []);

  const handleSave = async () => {
    await DataService.createTeam(name);
    fetchTeams();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Team</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">Cancel</Button>
        <Button onClick={handleSave} color="success">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

const EditTeamDialog = ({ open, onClose, team, fetchTeams }) => {
  const [name, setName] = useState('');
  const [manager, setManager] = useState('');
  const [members, setMembers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    if (team) {
      setName(team.name || '');
      setManager(team.manager?.name || '');
      setMembers(team.members || []);
    } else {
      setName('');
      setManager('');
      setMembers([]);
    }

    const fetchUsers = async () => {
      const users = await DataService.getAllUsers();
      setAllUsers(users);
    };

    fetchUsers();
  }, [team]);

  const handleRemoveMember = async (userId) => {
    if (team) {
      await DataService.removeUserFromTeam(userId, team.teamId);
      const updatedMembers = members.filter(member => member.userId !== userId);
      setMembers(updatedMembers);
    }
  };

  const handleAddMember = async () => {
    if (selectedUser && team) {
      await DataService.addUserToTeam(selectedUser, team.teamId, isManager);
      const newUser = allUsers.find(user => user.userId === selectedUser);
      const updatedMembers = [...members, { ...newUser, isManager }];
      setMembers(updatedMembers);
      setSelectedUser('');
      setIsManager(false);
    }
  };

  const handleSave = async () => {
    if (team) {
      await DataService.updateTeam(team.teamId, name, manager);
    } else {
      await DataService.createTeam(name, manager);
    }

    fetchTeams();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>'Edit Team'</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <List>
          {members.map((member) => (
            <ListItem key={member.userId}>
              <ListItemAvatar>
                <Avatar src={member.profilePicture}>
                  {member.name.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {member.name}
                    {member.isManager && (
                      <Chip
                        label="Manager"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 8 }}
                      />
                    )}
                  </>
                }
                secondary={member.email}
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveMember(member.userId)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-user-label">Select User</InputLabel>
          <Select
            labelId="select-user-label"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          >
            {allUsers.map((user) => (
              <MenuItem key={user.userId} value={user.userId}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={isManager}
              onChange={(e) => setIsManager(e.target.checked)}
              color="primary"
            />
          }
          label="Is Manager"
        />
        <Button
          variant="contained"
          color="info"
          startIcon={<AddIcon />}
          onClick={handleAddMember}
          disabled={!selectedUser}
        >
          Add Member
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">Cancel</Button>
        <Button onClick={handleSave} color="success">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const TagDialog = ({ open, onClose, tag, fetchTags }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(tag?.name || '');
  }, [tag]);

  const handleSave = async () => {
    if (tag) {
      await DataService.updateTag(tag.tagId, name);
    } else {
      await DataService.createTag(name);
    }
    fetchTags();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{tag ? 'Edit Tag' : 'Add Tag'}</DialogTitle>
      <DialogContent>
        <TextField label="Name" fullWidth value={name} onChange={(e) => setName(e.target.value)} margin="normal" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">Cancel</Button>
        <Button onClick={handleSave} color="success">{tag ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamManagement;
