import { createClient } from '@supabase/supabase-js';
import axios from 'axios';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);

const DataService = {};

const userId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return user?.id;
};

const apiRequest = async (method, url, data = null) => {
    try {
        const { data: sessionData } = await supabase.auth.getSession();
        const token = sessionData.session.access_token;

        const config = {
            method,
            url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Client-Url': window.location.origin
            },
            data
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        console.error(`Error making ${method} request to ${url}:`, error);
        throw error;
    }
};

DataService.getAllPosts = () => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/posts`);

DataService.createPost = (title, description, videoLocation, tags) => {
    return apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/posts/create`, { title, description, videoLocation, tags });
};

DataService.updatePost = (postId, videoLocation, title, description, tags) => {
    return apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/posts/update`, { postId, videoLocation, title, description, tags });
};

DataService.deletePost = (postId) => {
    return apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/posts/delete`, { postId });
};

DataService.likePost = (postId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/posts/like`, { postId });

DataService.getTeams = () => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/teams`);

DataService.getTeamDetails = (teamId) => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/teams/${teamId}`);

DataService.getUserById = (userId) => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/users/${userId}`);

DataService.getAllUsers = () => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/users`);

DataService.createTeam = (teamName) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/teams/create`, { teamName });

DataService.addUserToTeam = (userId, teamId, isManager) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/teams/addUserToTeam`, { userId, teamId, isManager });

DataService.addNewUser = async (name, email) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/add`, { name, email });

DataService.updateUserRole = (userId, newRole) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/updateRole`, { userId, newRole });

DataService.addComment = (postId, content) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/comments/add`, { postId, content });

DataService.updateUser = (userId, name, email, description, cliftonStrengths, enneagramNumber, teamAssignments, profilePicture) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/update`, { userId, name, email, profilePicture, description, cliftonStrengths, enneagramNumber, teamAssignments });

DataService.updateUserProfile = (userId, name, email, description, cliftonStrengths, enneagramNumber, profilePicture) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/update-profile`, { userId, name, email, profilePicture, description, cliftonStrengths, enneagramNumber });

DataService.deleteUser = (userId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/delete`, { userId });

DataService.updateTeam = (teamId, name) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/teams/update`, { teamId, name });

DataService.getUserFeed = async (tags = []) => {
    return await apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/users/feed`, { tags });
}

DataService.removeUserFromTeam = (userId, teamId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/teams/removeUser`, { userId, teamId });

DataService.getAllTags = () => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/tags`);

DataService.getTag = (tagId) => apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/tags/${tagId}`);

DataService.createTag = (name) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/tags/create`, { name });

DataService.updateTag = (tagId, name) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/tags/update`, { tagId, name });

DataService.deleteTag = (tagId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/tags/delete`, { tagId });

DataService.pinPost = (postId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/posts/pin`, { postId });

DataService.scheduleNotification = (message, scheduledFor, userId) => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/notifications/schedule`, { message, scheduledFor, userId });

DataService.getUserNotifications = () => apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/notifications`);

DataService.getUserStreak = async () => {
    try {
        const fetchedUserId = await userId();
        if (!fetchedUserId) throw new Error('User ID is not available');

        const response = await apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/users/${fetchedUserId}/streak`);
        if (response && response.streak !== undefined) {
            return response.streak;
        } else {
            throw new Error('Invalid response structure for streak data');
        }
    } catch (error) {
        console.error('Error fetching user streak:', error);
        throw error;
    }
}

DataService.removeUser = async (userId) => {
    try {
        const { data, error } = await supabase.functions.invoke('delete-user', {
            body: { user_id: userId }
        });
        if (error) throw error;
        return data;
    } catch (error) {
        console.error('Error removing user:', error);
        throw error;
    }
};

DataService.uploadPublicBlob = async (file) => {
    const { data, error } = await supabase.storage
        .from(process.env.REACT_APP_STORAGE_PUBLIC_BUCKET)
        .upload(`public/${file.name}-${Date.now()}`, file);
    if (error) throw error;
    return data.path;
}

DataService.getPublicBlobUrl = async (blobPath) => {
    const { data, error } = await supabase.storage.from(process.env.REACT_APP_STORAGE_PUBLIC_BUCKET).getPublicUrl(blobPath);
    if (error) throw error;
    return data;
}

DataService.uploadBlob = async (file) => {
    const { data, error } = await supabase.storage
        .from(process.env.REACT_APP_STORAGE_PRIVATE_BUCKET)
        .upload(`public/${file.name}-${Date.now()}`, file);
    if (error) throw error;
    return data.path;
}

DataService.fetchBlobReference = async (blobPath) => {
    const { data } = await supabase.storage.from(process.env.REACT_APP_STORAGE_PRIVATE_BUCKET).createSignedUrl(blobPath, 3600);
    return data.signedUrl;
}

DataService.isAuthenticated = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return !!user; // Return true if user exists, otherwise false
};

DataService.getCurrentUser = async () => {
    try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        return user;
    } catch (error) {
        console.error('Error getting current user:', error);
        throw error;
    }
};

// New method to send post notification to a specific user
DataService.sendPostNotificationToUser = (userId, postId) => {
    return apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/notifications/sendPost`, {
        userId,
        postId,
        isForTeam: false
    });
};

// New method to send post notification to a specific team
DataService.sendPostNotificationToTeam = (teamId, postId) => {
    return apiRequest('post', `${process.env.REACT_APP_API_BASE_URL}/notifications/sendPost`, {
        teamId,
        postId,
        isForTeam: true
    });
};

DataService.getPostById = async (postId) => {
    return apiRequest('get', `${process.env.REACT_APP_API_BASE_URL}/posts/${postId}`);
}


export default DataService;